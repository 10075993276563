import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import getIn from 'lodash/get';
import { TiArrowRight } from 'react-icons/ti';
import breakpoints from 'utils/breakpoints';
import PureLink from './Pure';

const Arrow = styled(TiArrowRight)`
  font-size: 24px;
  display: inline-block;
  transform: translateY(2px);
`;

const StyledLink = styled(PureLink)`
  color: ${props => getIn(props, 'theme.link.textColor', '#0276f1')};
  font-size: 0.875rem;
  line-height: 1.625rem;
  text-decoration: none;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

function Link({ icon, children, ...props }) {
  return (
    <StyledLink {...props}>
      {children}
      {icon}
    </StyledLink>
  );
}

Link.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
};

Link.defaultProps = {
  icon: <Arrow />,
  children: null,
};

export default Link;
