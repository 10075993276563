import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import Card from 'components/Card';
import Paragraph from 'components/Paragraph';
import Link from 'components/Link';
import { ReactComponent as RequestDemoIcon } from 'assets/contact/request-demo.svg';
import { ReactComponent as GeneralMediaIcon } from 'assets/contact/general-and-media.svg';
import { ReactComponent as ProductsSupportIcon } from 'assets/contact/products-support.svg';
import { ReactComponent as CareersIcon } from 'assets/contact/careers.svg';

const StyledRow = styled(Row)`
  margin: 24px 0;

  @media (min-width: ${breakpoints.md}) {
    margin: -36px 0 0;
  }
`;

const StyledCard = styled(Card)`
  background: transparent;
  padding: 0;

  h5 {
    color: #ffffff;
  }

  ${Paragraph} {
    margin-top: 0;
    color: #ffffff;
    opacity: 0.5;
  }
`;

const ContactItem = styled.div`
  box-sizing: border-box;
  margin: 0.5rem 0;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin: 1rem 0 2rem;
  }
`;

function ContactList() {
  return (
    <StyledRow>
      <Col lg={6}>
        <ContactItem>
          <StyledCard icon={<RequestDemoIcon />} title="Permintaan Demo">
            <Paragraph>
              Cari tau lebih lanjut bagaimana produk kami dapat membantu
              mengembangkan bisnis anda
            </Paragraph>
            <Link to="mailto:info@trustingsocial.com">Ajukan Sekarang</Link>
          </StyledCard>
        </ContactItem>
      </Col>
      <Col lg={6}>
        <ContactItem>
          <StyledCard icon={<GeneralMediaIcon />} title="General & Media">
            <Paragraph>
              Semua pertanyaan umum silahkan untuk disampaikan kepada kami
            </Paragraph>
            <Link to="mailto:contact@trustingsocial.com">Kirim pesan ke kami</Link>
          </StyledCard>
        </ContactItem>
      </Col>
      <Col lg={6}>
        <ContactItem>
          <StyledCard icon={<ProductsSupportIcon />} title="Dukungan produk">
            <Paragraph>
              Ingin interaksi langsung dengan tim support kami? Temui kami
              di sini
            </Paragraph>
            <Link to="mailto:support@trustingsocial.com">Dapatkan dukungan</Link>
          </StyledCard>
        </ContactItem>
      </Col>
      <Col lg={6}>
        <ContactItem>
          <StyledCard icon={<CareersIcon />} title="Careers">
            <Paragraph>
              Apakah kita memicu rasa ingin tahu anda? Berinteraksilah dengan
              tim rekrutmen kami
            </Paragraph>
            <Link to="mailto:hr@trustingsocial.com">Hubungi rekruiter</Link>
          </StyledCard>
        </ContactItem>
      </Col>
    </StyledRow>
  );
}

export default ContactList;
