import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { light, dark } from 'themes';
import breakpoints from 'utils/breakpoints';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import Spacer from 'components/Spacer';
import { HEADER_HEIGHT } from 'components/Header';
import ContactList from 'components/Contact/ContactList';
import OfficeList from 'components/Contact/OfficeList';
import introBg from 'assets/contact/intro-bg.png';

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT + 35}px;
  padding-bottom: 70px;
  background-color: #141414;
  background-image: url(${introBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${HEADER_HEIGHT + 145}px;
    padding-bottom: 107px;
  }
`;

const OurOffices = styled.section`
  padding: 100px 0;
  background: #ffffff;

  @media (min-width: ${breakpoints.md}) {
    padding: 163px 0 150px 0;
  }
`;

const EmailAddress = styled.a`
  display: inline-block;
  margin-top: 80px;
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.75rem;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 1px solid #ffffff;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 130px;
  }
`;

function ContactPage() {
  return (
    <Layout headerTheme={dark} fullSize pageTitle="Hubungi Kami">
      <ThemeProvider theme={light}>
        <Intro>
          <Container>
            <Row>
              <Col xs={8} sm={6} md={5} lg={4} xl={4}>
                <Heading
                  name="Hubungi kami"
                  title="Dengan senang hati kami akan membantu anda"
                />
                <EmailAddress href="mailto:contact@trustingsocial.com">
                  contact@trustingsocial.com
                </EmailAddress>
              </Col>
              <Col md={6} offset={{ md: 1, lg: 2, xl: 2 }}>
                <ContactList />
              </Col>
            </Row>
          </Container>
        </Intro>
      </ThemeProvider>
      <OurOffices>
        <Container>
          <Heading
            name="Kantor kantor kami"
            title="Dengan ❤️ kami melayani tamu di kantor kami"
          />
          <Paragraph>
            Datang dan bergabunglah dengan kami untuk menikmati secangkir teh
            dan menyaksikan sendiri bagaimana kami secara aktif mendorong
            inklusi keuangan.
          </Paragraph>
          <Visible xs sm>
            <Spacer space="15px" />
          </Visible>
          <OfficeList />
        </Container>
      </OurOffices>
    </Layout>
  );
}

export default ContactPage;
