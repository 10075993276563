import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Subtitle = styled.p`
  opacity: 0.5;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 0.75rem;
  color: #1c2f45;
  letter-spacing: 1px;
`;

function CardTitle({ title, subtitle }) {
  return (
    <>
      <Subtitle>{subtitle}</Subtitle>
      <div>{title}</div>
    </>
  );
}

CardTitle.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
};

export default CardTitle;
