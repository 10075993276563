import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import Card from 'components/Card';
import Paragraph from 'components/Paragraph';
import Link from 'components/Link';
import { ReactComponent as Saigon } from 'assets/contact/saigon.svg';
import { ReactComponent as Singapore } from 'assets/contact/singapore.svg';
import { ReactComponent as Manila } from 'assets/contact/manila.svg';
import { ReactComponent as Mumbai } from 'assets/contact/mumbai.svg';
import { ReactComponent as Jakarta } from 'assets/contact/jakarta.svg';
import CardTitle from './CardTitle';

const OFFICES = [
  {
    icon: <Singapore />,
    country: 'Singapore',
    city: 'Singapore',
    googleMapsLink: 'https://goo.gl/maps/47zy8JBk9QJndwxS8',
    address: 'Suntec Tower 2, #07-01 Singapore',
  },
  {
    icon: <Saigon />,
    country: 'Vietnam',
    city: 'Ho Chi Minh City',
    googleMapsLink: 'https://goo.gl/maps/iQw6h7tYFz9bGgZP7',
    address:
      '8th Floor, Havana Centre, 132 Ham Nghi, Ben Thanh Ward, Dist. 1, Ho Chi Minh City.',
  },
  {
    icon: <Manila />,
    country: 'Philippines',
    city: 'Manila',
    googleMapsLink: 'https://goo.gl/maps/ZKvCkSu4U5raR6Lh9',
    address: '22nd Floor Marajo Tower, 312 26th Street, Bonifacio Global City',
  },
  {
    icon: <Jakarta />,
    country: 'Indonesia',
    city: 'Jakarta',
    googleMapsLink: 'https://goo.gl/maps/X7Rx7igqvgs9Pzox8',
    address:
      'Treasury Tower, 51st Floor District 8, Lot 28 Jl. Jend. Sudirman Kav. 52-54, Jakarta 12190.',
  },
  {
    icon: <Mumbai />,
    country: 'India',
    city: 'Mumbai',
    googleMapsLink: 'https://goo.gl/maps/qUkCoeQYdryRpA8w7',
    address:
      'Wework BKC, C - 20, G Block Rd, G Block BKC, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra 400051',
  },
];

const OfficeItem = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
  }
`;

const StyledCard = styled(Card)`
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  background: #ffffff;

  i {
    margin: 0 0 26px 0;
  }

  ${Paragraph} {
    margin-top: 0;
    margin-bottom: 60px;
  }
`;

function OfficeList() {
  // eslint-disable-next-line react/prop-types
  const renderOffice = ({ country, icon, city, address, googleMapsLink }) => (
    <Col key={`${city}-${country}`} md={6} lg={4}>
      <OfficeItem>
        <StyledCard
          icon={icon}
          title={<CardTitle subtitle={country} title={city} />}
          footer={
            <Link target="_blank" to={googleMapsLink}>
              Perlihatkan di peta
            </Link>
          }
        >
          <Paragraph>{address}</Paragraph>
        </StyledCard>
      </OfficeItem>
    </Col>
  );

  return <Row justify="center">{OFFICES.map(renderOffice)}</Row>;
}

export default OfficeList;
